import { mapValues } from 'lodash';
import * as React from 'react';
import { useQuery } from 'react-query';
import { Box, Flex, FlexProps, Image, SxProps } from 'rebass/styled-components';
import { UppercaseText } from '@deepstream/ui-kit/elements/text/UppercaseText';
import { useApi, wrap } from './api';

export const sizes = {
  xxs: 18,
  xxs1: 20,
  xs: 25,
  sm: 30,
  md: 40,
  lg: 70,
  xl: 140,
} as const;

export type AvatarWidth = keyof typeof sizeToPx;

const sizeToPx = mapValues(sizes, size => `${size}px`);

export const sizeToInitialsFontSize = {
  xxs: '9px',
  xxs1: '9px',
  xs: '10px',
  sm: '11px',
  md: '16px',
  lg: '24px',
  xl: '50px',
} as const;

export type AvatarContainerProps = {
  width: AvatarWidth;
  children: React.ReactNode;
  style?: React.CSSProperties;
} & FlexProps;

export const AvatarContainer = ({ width, sx, style, children, ...props }: AvatarContainerProps) => {
  const size = sizeToPx[width];

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="navy"
      color="white"
      width={size}
      height={size}
      sx={{
        overflow: 'hidden',
        borderRadius: '50%',
        boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.2)',
        lineHeight: size,
        border: 'lightGray2',
        ...sx,
      }}
      style={style}
      {...props}
    >
      {children}
    </Flex>
  );
};

const AvatarInitials = ({ width, initials }) => {
  const fontSize = sizeToInitialsFontSize[width];

  return (
    <Box>
      <UppercaseText color="white" fontSize={fontSize} data-test="avatar-initials">
        {initials}
      </UppercaseText>
    </Box>
  );
};

const AvatarImage = ({ src }) => (
  <Image src={src} sx={{ height: '100%', objectFit: 'cover' }} />
);

const getUserInitials = user => {
  if (!user) return '';

  const firstInitial = user.firstName?.trim() ? user.firstName.trim()[0] : '';
  const secondInitial = user.lastName?.trim() ? user.lastName.trim()[0] : '';

  return `${firstInitial}${secondInitial}`;
};

export type AvatarProps = {
  userId: string;
  avatarId?: string | null | undefined;
  width?: keyof typeof sizeToPx;
  style?: React.CSSProperties;
} & SxProps;

export const Avatar: React.FC<AvatarProps> = ({
  userId,
  avatarId,
  width = 'sm',
  sx,
  style,
}) => {
  const api = useApi();

  const { data: user } = useQuery(
    ['user', { userId }],
    wrap(api.getUser),
    {
      staleTime: 60 * 60 * 1000,
    },
  );

  const { data: avatarUrl } = useQuery(
    ['avatar', { avatarId }],
    wrap(api.getAvatar),
    {
      staleTime: 60 * 60 * 1000,
      enabled: Boolean(avatarId),
    },
  );

  const { data: userAvatar } = useQuery(
    ['userAvatar', { userId }],
    wrap(api.getUserAvatar),
    {
      staleTime: 60 * 60 * 1000,
      enabled: !avatarId && Boolean(user),
    },
  );

  return (
    <AvatarContainer width={width} sx={sx} style={style}>
      {avatarId && avatarUrl ? (
        <AvatarImage src={avatarUrl.url} />
      ) : user ? (
        // An `avatarId` explicitly set to `null` represents an avatar
        // without a photo. This is used by the AvatarField to clear the
        // current avatar (ie: show initials) before the change is applied
        // to the user model on the server. In other words, we needed a way
        // to show the initials while the `user.avatarId` still pointed to
        // a real image.
        user.avatarId && avatarId !== null && userAvatar?.url ? (
          <AvatarImage src={userAvatar.url} />
        ) : (
          <AvatarInitials width={width} initials={getUserInitials(user)} />
        )
      ) : (
        null
      )}
    </AvatarContainer>
  );
};
