import { uniqueId, reject, findIndex } from 'lodash';
import { createMachine, assign, spawn } from 'xstate';
import { Attachment } from '@deepstream/common/rfq-utils';
import { uploadMachine } from './uploadMachine';

/*
type UploadsMachineContext = {
  uploadFn: any;
  uploads: Array<{ _id: string; ref: Interpreter<any> }>;
  attachments: Attachment[];
  maxFileSize: number | null;
  validMimeTypes: string[] | null;
};

type AddUploadEvent = { type: 'ADD_UPLOAD'; file: File };
type CancelUploadEvent = { type: 'CANCEL_UPLOAD'; _id: string };
type RemoveUploadEvent = { type: 'REMOVE_UPLOAD'; _id: string; attachmentId: string };
type CompleteUploadEvent = { type: 'COMPLETE_UPLOAD'; _id: string };

type Event =
  AddUploadEvent |
  CancelUploadEvent |
  CompleteUploadEvent |
  RemoveUploadEvent;
*/

export const uploadsMachine = createMachine<any>({
  id: 'uploads',
  predictableActionArguments: true,
  initial: 'initializing',
  context: {
    // The function in charge for uploading. This will be passed to the
    // child state machines.
    uploadFn: null,

    // Array of child state machines corresponding to each upload
    uploads: [],

    // This array will populate with Attachments generated from each successful
    // upload. If this array is populated on initialization, the corresponding
    // state machines are immediately transitioned into a completed state.
    attachments: [],

    // Array of attachment IDs that have been saved on the server;
    // We are using this in order to populate the `isSaved` flag for each upload.
    savedAttachmentIds: null,

    // the translation function
    t: null,

    /**
     * The maximum allowed size of uploaded files in bytes.
     */
    maxFileSize: null,
    /**
     * If not null, an array of allowed mime types.
     */
    validMimeTypes: null,
  },
  states: {
    // Transient state to that we can setup the child machines for the attachments
    initializing: {
      entry: 'initCompletedUploads',
      always: 'initialized',
    },
    initialized: {},
  },
  on: {
    ADD_UPLOAD: {
      actions: 'addUpload',
    },
    CANCEL_UPLOAD: {
      actions: 'removeUpload',
    },
    REMOVE_UPLOAD: {
      actions: ['removeUpload', 'removeAttachment'],
    },
    COMPLETE_UPLOAD: {
      actions: 'addAttachment',
    },
  },
}, {
  actions: {
    initCompletedUploads: assign({
      uploads: context =>
        context.attachments.map((attachment: Attachment) => ({
          _id: attachment._id,
          ref: spawn(
            uploadMachine.withContext({
              ...uploadMachine.context,
              _id: attachment._id,
              attachment,
              isSaved: context.savedAttachmentIds ? context.savedAttachmentIds.includes(attachment._id) : null,
              t: context.t,
            }),
            { name: uniqueId('upload') },
          ),
        })),
    }),
    addUpload: assign({
      uploads: (context, { file }) => {
        const _id = uniqueId('file');

        return context.uploads.concat({
          _id,
          ref: spawn(
            uploadMachine.withContext({
              ...uploadMachine.context,
              _id,
              file,
              uploadFn: context.uploadFn,
              t: context.t,
              maxFileSize: context.maxFileSize,
              validMimeTypes: context.validMimeTypes,
            }),
            { name: uniqueId('upload') },
          ),
        });
      },
    }),
    removeUpload: assign({
      uploads: (context, event) => reject(context.uploads, { _id: event._id }),
    }),
    removeAttachment: assign({
      attachments: (context, event) => reject(context.attachments, { _id: event.attachmentId }),
    }),
    addAttachment: assign({
      attachments: (context, event) => {
        const index = findIndex(context.uploads, { _id: event._id });
        const attachments: Attachment[] = context.attachments.slice();
        attachments.splice(index, 0, event.attachment);
        return attachments;
      },
    }),
  },
});
