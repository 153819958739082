export enum MimeType {
  CSV = 'text/csv',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  HTML = 'text/html',
  JSON = 'application/json',
  PDF = 'application/pdf',
  PLAIN_TEXT = 'text/plain',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
}
