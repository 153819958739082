import { useMemo, useCallback, useEffect } from 'react';
import { Attachment } from '@deepstream/common/rfq-utils';
import { useMachine } from '@xstate/react';
import { identity } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { uploadMachine } from './uploadMachine';
import { uploadsMachine } from './uploadsMachine';

export type Upload = {
  _id: string;
  status: 'unknown' | 'idle' | 'uploading' | 'completed' | 'failed' | 'deleted';
  matches: any;
  progress: number;
  attempts: number;
  isSaved: boolean | null;
  file: File; // Input
  attachment: Attachment; // Output
  error: string | null;
  canRetry: boolean;
  start: (file: File) => void;
  cancel: () => void;
  remove: () => void;
  retry: () => void;
  clear: () => void;
  replace: (file?: File | null) => void;
};

// Convert the upload state machine interpreter into a more straightforward representation
const uploadMachineToObject = ({ state, send }: { state: any; send: any }): Upload => ({
  ...state.context,
  status: state.value,
  matches: state.matches,
  retry: () => send('RETRY'),
  remove: () => send('DELETE'),
  cancel: () => send('CANCEL'),
  clear: () => send('CLEAR'),
  start: (file: File) => send('START', { file }),
  replace: (file: File) => send('REPLACE', { file }),
});

type UseUploadsProps = {
  initialAttachments?: Attachment[];
  initialFiles?: File[];
  savedAttachmentIds?: string[];
  uploadFn: (...args: any[]) => Promise<any>;
  onChange: (attachments: Attachment[]) => void;
  onUploadStart: (file: File) => void;
  limit: number;
  /**
   * The maximum allowed size of uploaded files in bytes.
   */
  maxFileSize?: number | null;
  /**
   * If not null, an array of allowed mime types.
   */
  validMimeTypes?: string[] | null;
};

/*
 * A hook to manage list of uploaded files. A successful upload takes an HTML5 File and
 * returns a DeepStream Attachment.
 */
export const useUploads = ({
  initialAttachments,
  initialFiles,
  uploadFn,
  onUploadStart,
  onChange,
  limit,
  savedAttachmentIds,
  maxFileSize = null,
  validMimeTypes = null,
}: UseUploadsProps) => {
  const { t } = useTranslation();

  const machine = useMemo(
    () => uploadsMachine.withContext({
      attachments: initialAttachments || [],
      savedAttachmentIds,
      uploadFn,
      t,
      maxFileSize,
      validMimeTypes,
    }),
    [initialAttachments, savedAttachmentIds, t, uploadFn, maxFileSize, validMimeTypes],
  );

  const [state, send] = useMachine(machine);

  const attachments = state.context.attachments as Attachment[];
  const uploads = state.context.uploads.map((upload: any) => uploadMachineToObject(upload.ref)) as Upload[];

  const addUpload = useCallback(
    (file: File) => {
      onUploadStart(file);
      send('ADD_UPLOAD', { file });
    },
    [send, onUploadStart],
  );

  const addUploads = useCallback(
    (files: FileList | File[]) => {
      // truncate the list to respect the limit
      files = Array.from(files).slice(0, limit - attachments.length);

      for (const file of files) {
        addUpload(file);
      }
    },
    [addUpload, limit, attachments.length],
  );

  useEffect(
    () => {
      if (initialFiles && initialFiles.length) {
        addUploads(initialFiles);
      }
    },
    // Breaking the react-hooks/exhaustive-deps eslint rule is intentional. This effect is meant to run only on mount
    [], // eslint-disable-line
  );

  // Call `onChange` whenever `attachments` changes
  useWatchValue(attachments, onChange);

  return { attachments, uploads, addUploads, addUpload };
};

type UseUploadProps = {
  initialFile?: File;
  initialAttachment?: Attachment;
  uploadFn: (...args: any[]) => Promise<any>;
  onChange: (attachment: Attachment) => void;
  /**
   * The maximum allowed size of uploaded files in bytes.
   */
  maxFileSize?: number | null;
  /**
   * If not null, an array of allowed mime types.
   */
  validMimeTypes?: string[] | null;
};

// For an uploadMachine that works without a parent, we nullify actions that send to parent
const uploadMachineNoParent = uploadMachine.withConfig({
  actions: {
    sendDelete: identity,
    sendComplete: identity,
    sendCancel: identity,
  },
});

export const useUpload = ({
  initialFile,
  initialAttachment,
  uploadFn,
  onChange,
  maxFileSize = null,
  validMimeTypes = null,
}: UseUploadProps) => {
  const machine = uploadMachineNoParent.withContext({
    file: initialFile,
    attachment: initialAttachment,
    uploadFn,
    maxFileSize,
    validMimeTypes,
  });

  const [state, send] = useMachine(machine);

  // Call `onChange` whenever `attachment` changes
  useWatchValue(state.context.attachment, onChange);

  return uploadMachineToObject({ state, send });
};
