export enum AttachmentEntityType {
  REQUEST = 'request',
  REQUEST_TEMPLATE = 'requestTemplate',
  CONTRACT = 'contract',
  CONTRACT_TEMPLATE = 'contractTemplate',
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRE_TEMPLATE = 'questionnaireTemplate',
}

export enum AttachmentEventType {
  ATTACHMENT_DOWNLOADED = 'attachment-downloaded',
}

export type AttachmentEvent = {
  type: AttachmentEventType;
  attachmentId: string;
  userId: string;
  userName: string;
  companyId: string;
  entityType: AttachmentEntityType;
  entityId: string;
  timestamp: Date;
};
