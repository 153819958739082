type UnknownRecord = Record<string, unknown>;

export class DsError<TMeta extends UnknownRecord = UnknownRecord> extends Error {
  public meta: TMeta;

  public constructor(message: string, meta?: TMeta) {
    super(message);
    this.meta = meta ?? {} as TMeta;
    this.name = this.constructor.name;
  }

  public serialize() {
    return {
      name: this.name,
      message: this.message,
      stack: this.stack,
      ...this.meta,
    };
  }

  public toJSON() {
    return {
      name: this.name,
      message: this.message,
      meta: this.meta,
    };
  }
}

export class CriticalError extends DsError {}

export class InitializationError extends DsError {}

export class ObjectDoesNotExist extends DsError {}

export class LambdaHandlerError extends DsError {}

export class LambdaInvocationError extends DsError {}

export class LockedModelError extends DsError {
  /**
   * Custom HTTP status code to indicate that the
   * server cannot process the request because the
   * requested event-sourced model is locked for
   * processing.
   *
   * Unassigned according to
   * https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
   *
   * NB This is a 4xx code to avoid triggering CloudWatch alarms
   */
  static code = 433 as const;
}

export class ModelStatusMismatchError extends DsError<{ expectedStatus?: string; actualStatus?: string }> {
  /**
   * Custom HTTP status code to indicate that the
   * model's status doesn't match the expected status
   * (`expectedStatus`) or matches an undesired status
   * (`actualStatus`).
   *
   * Unassigned according to
   * https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
   *
   * NB This is a 4xx code to avoid triggering CloudWatch alarms
   */
  static code = 434 as const;
}

export class ModelDeletedError extends DsError {
  /**
   * Custom HTTP status code to indicate that the
   * model isn't available because it has been deleted.
   *
   * Unassigned according to
   * https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
   *
   * NB This is a 4xx code to avoid triggering CloudWatch alarms
   */
  static code = 435 as const;
}

export class ValidationError extends DsError {
  static code = 400 as const;
}

export class RouteValidationError extends ValidationError {
}

export class IntegrationTriggerError extends DsError {}

export class PermissionsError extends DsError {}

export class UnexpectedStateError extends DsError {}

export class RenderNotificationError extends DsError {}

export class MissingInterpolationError extends DsError {}
export class MissingTranslationError extends DsError {}
